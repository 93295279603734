/* Alap stílusok a popup overlayhez */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup stílusok */
  .popup {
    background: #161616;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 800px;
    color: #fff;
    position: relative;
  }
  
  /* Bezárás gomb stílusok */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #fff;
    font-size: 40px;
    cursor: pointer;
  }
  
  /* Popup tartalom stílusok */
  .popup-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .product-card {
    text-align: center;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card-header h2 {
    margin: 0;
  }
  
  .separator {
    flex-grow: 1;
    height: 1px;
    background: #444;
    margin-left: 10px;
  }
  
  .image-container {
    margin-top: 10px;
  }
  
  .product-image {
    max-width: 100%;
    height: auto;
  }
  
  .form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Mennyiségválasztó stílusok */
  .quantity-selector {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .quantity-selector button {
    background: #444;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .quantity-selector input {
    width: 50px;
    text-align: end;
    background: #222;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-size: 22px;
  }
  
  /* Form stílusok */
  .form-container {
    display: flex;
    flex-direction: column;
    height: 50vh;
    overflow-y: auto;
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: #888 #222;
}

.form-container::-webkit-scrollbar {
    width: 8px;
}

.form-container::-webkit-scrollbar-track {
    background: #222;
    border-radius: 10px;
}

.form-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 2px solid #222;
}
  
  .popup-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .popup-form label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-family: Ppeditorialnew, sans-serif;
  }
  
  .popup-form input,
  .popup-form textarea {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #222;
    color: #fff;
  }
  
  .popup-form textarea {
    resize: vertical;
  }
  
  .stripe-placeholder {
    width: 100%;
    background: #333;
    margin: 20px 0;
  }
  
  .form-section button {
    background: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
  }
  .form-section .submitbutton {
    font-size: 40px;
  }

  .formpopupbuttom {
    background: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    font-size: 40px;
  }
  
  @media (min-width: 768px) {
    .popup-content {
      flex-direction: row;
      gap: 30px;
    }
    .form-section {
      width: 50%;
    }
    .product-card {
      width: 50%;
    }
    .payment-container {
        margin-top: 50px;
    }
    
  }
  @media (max-width: 768px) {
    .product-image {
        max-width: 60%;
        height: auto;
    }
    .form-container {
        height: 30vh;
    }
    .payment-container {
        margin-top: 20px;
    }
    .popup {
      transform: scale(0.8);
    }
  }