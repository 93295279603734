:root {
  --charcole: #161616;
  --white-smoke: #f0f0f0;
  --black: black;
  --white: white;
  --blue: #146ef5;
  --pink: #dc58be;
  --yellow: #ecfd6f;
}

body {
  background-color: var(--charcole);
  color: var(--white-smoke);
  font-family: Bulevar Poster, sans-serif;
  font-size: 1rem;
  line-height: 1;
}

h1 {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18vw;
  font-weight: 400;
  line-height: .8;
}

h2 {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 6rem;
  font-weight: 400;
  line-height: .8;
}

p {
  margin-bottom: 0;
  font-family: Ppeditorialnew, sans-serif;
  font-size: 1.3rem;
  font-style: italic;
  font-weight: 200;
  line-height: 1.5;
}

a {
  text-decoration: underline;
}

.html {
  position: absolute;
  inset: 0% auto auto 0%;
}

.rolunkhero {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.rolunkhero.ab {
  height: 100vh;
  position: relative;
}

.rolunkhero.bg-blue {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  background-color: #0000;
  border-left: 1px solid #7f7a7a40;
  border-right: 1px solid #7f7a7a40;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  min-width: auto;
  min-height: 100%;
  padding: 1rem 2rem;
  display: flex;
}

.rolunkhero.bg-pink {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  background-color: #0000;
  border-left: 1px solid #7f7a7a40;
  border-right: 1px solid #7f7a7a40;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  padding: 1rem 2rem;
  display: flex;
}

.info-col-child {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 23rem;
  padding-top: 1rem;
  padding-bottom: .2rem;
  display: flex;
}

.info-col-child.pop-up {
  grid-column-gap: 1.2rem;
  grid-row-gap: 1.2rem;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 2.8rem;
  position: relative;
}

.section {
  z-index: 1;
  width: 100%;
  position: relative;
}

.section.split-col {
  background-color: #000;
  height: 100vh;
  display: flex;
  overflow: clip;
}

.rolunkherocontainer {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.rolunkherocontainer.flip {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  background-image: linear-gradient(#0000 31%, #000 50%);
  flex-flow: column;
}
.primary-btn.menu:hover .btn-whipe {
  height: 100%;
}
.primary-btn.ghost:hover .btn-whipe {
  height: 100%;
}
.btn-whipe {
  width: 100%;
  height: 0%;
  position: absolute;
  inset: auto auto 0% 0%;
  transition: height 0.6s ease;
}

.btn-whipe.bg-white {
  background-color: #fff;
  font-family: Raleway Variablefont Wght, sans-serif;
}

.button-text-ab {
  padding-top: .2rem;
  font-family: Oswald, sans-serif;
  font-size: 1rem;
  position: absolute;
  inset: 0% auto auto 0%;
}

.button-text-ab.text-black {
  color: #ffffff;
  font-family: Oswald, sans-serif;
  font-size: 1rem;
}

.col-content-parent {
  z-index: 1;
  grid-column-gap: 0rem;
  grid-row-gap: 0rem;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 6rem 3rem 3rem;
  display: flex;
  position: absolute;
  inset: 0% auto auto 0%;
}

.video-bg {
  z-index: 0;
  opacity: .7;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  inset: 0% auto auto 0%;
}

.rolunkheroheadingdiv {
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.rolunkheroheadingdiv.padding-btm {
  color: var(--white-smoke);
  padding-bottom: .2rem;
  text-decoration: none;
}

.rolunkheroheadingdiv.full-right {
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.info-col-parent {
  width: 100%;
  overflow: hidden;
}

.col-left {
  background-color: var(--black);
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100svh;
  transition: padding .8s cubic-bezier(.77, 0, .175, 1);
  display: flex;
  position: absolute;
  left: 0;
}

.col-left:hover {
  padding: 4rem;
}

.parent {
  grid-column-gap: 1.2rem;
  grid-row-gap: 1.2rem;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.parent.flex-split {
  grid-column-gap: 1.8rem;
  grid-row-gap: 1.8rem;
  justify-content: space-between;
  align-items: flex-start;
}

.parent.class-type {
  flex-flow: column;
}

.parent.flex-split {
  z-index: 0;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-end;
}

.col-right {
  background-color: var(--black);
  width: 50%;
  height: 100svh;
  transition: padding .8s cubic-bezier(.77, 0, .175, 1);
  position: absolute;
  right: 0;
}

.col-right:hover {
  padding: 4rem;
}

.button-text {
  padding-top: .2rem;
  font-family: Oswald, sans-serif;
  font-size: 1rem;
}

.button-text.text-white {
  color: #fff;
  font-size: 1rem;
}

.tag-sm {
  text-transform: uppercase;
  border: 1px solid #fff3;
  padding: .6rem 1rem;
  font-family: Ppfraktionmono, sans-serif;
  font-size: .9rem;
  font-weight: 400;
  display: inline-block;
}

.tag-sm.nav {
  z-index: 1;
  font-family: Raleway Variablefont Wght, sans-serif;
  font-size: .9rem;
  position: relative;
}

.tag-sm.intro {
  font-family: Oswald, sans-serif;
  font-size: 1rem;
}

.primary-btn {
  z-index: 1;
  color: #000;
  text-transform: uppercase;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .5rem 1.2rem .7rem;
  font-family: Ppfraktionmono, sans-serif;
  font-size: .9rem;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.primary-btn.ghost {
  color: #fff;
  border: 1px solid #fff;
}

.primary-btn.ghost.dark {
  border-color: #000;
  border-left-style: none;
  border-right-style: none;
}

.primary-btn.ghost.dark.w--current {
  color: #000;
  border-color: #000;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}

.primary-btn.menu {
  border-bottom: 1px solid #fff3;
  border-left: 1px solid #fff3;
  border-right: 1px solid #fff3;
  border-radius: 0;
  justify-content: flex-start;
  align-items: center;
  padding: .6rem 0 .8rem .8rem;
}

.primary-btn.menu.one.w--current, .primary-btn.menu.two, .primary-btn.menu.three, .primary-btn.menu.four {
  z-index: 99999;
}

.nav-bar {
  z-index: 99;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.4rem;
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  position: fixed;
  inset: 0% auto auto 0%;
}

.logo {
  mix-blend-mode: difference;
  object-fit: cover;
  height: 3rem;
}

.span-text {
  display: inline-block;
}

.span-text.one, .span-text.two, .span-text.three, .span-text.four, .span-text.five {
  line-height: 1;
}

.border-line {
  background-color: var(--white-smoke);
  width: 100%;
  height: 2px;
  position: absolute;
  inset: auto auto 0% 0%;
}

.grid-main {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.grid-main.image {
  grid-column-gap: 110px;
  grid-row-gap: 110px;
  border-top: 1px solid #7f7a7a40;
  border-bottom: 1px solid #7f7a7a40;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 0;
}

.grid-main.text {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  display: block;
}

.nav-flex {
  justify-content: space-between;
  display: flex;
}

.text-sm {
  opacity: .6;
  text-transform: uppercase;
  font-family: Oswald, sans-serif;
  font-size: 1rem;
  transition: opacity .5s;
}

.text-sm:hover {
  opacity: 1;
}

.hero-parent {
  grid-column-gap: 10rem;
  grid-row-gap: 10rem;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 60px;
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
}

.header-parent {
  grid-column-gap: 1.2rem;
  grid-row-gap: 1.2rem;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding-top: 14rem;
  display: flex;
}

.serif {
  text-transform: none;
  font-family: Oswald, sans-serif;
  font-size: 2.6rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.1;
}

.text-parent {
  grid-column-gap: 1.8rem;
  grid-row-gap: 1.8rem;
  flex-flow: column;
  display: flex;
}

.body {
  transition: opacity .2s cubic-bezier(.175, .885, .32, 1.275);
}

.background-content {
  z-index: 0;
  perspective: 1000px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100svh;
  display: flex;
  position: absolute;
}

.title-name-intro {
  text-align: center;
  font-family: Bulevar Poster, sans-serif;
  font-size: 14vw;
  line-height: .75;
}

.col-left-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.intro-cover {
  z-index: 9999;
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  inset: 0% auto auto 0%;
}

.flex-menu {
  z-index: 99999;
  display: flex;
  position: relative;
}

.menu-parent {
  z-index: 2;
  grid-column-gap: .2rem;
  grid-row-gap: .2rem;
  border: 1px solid #fff;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  min-width: 2.2rem;
  transition: background-color .4s;
  display: flex;
  position: relative;
}

.menu-parent:hover {
  background-color: #ffffff1a;
}

.menu-bar {
  background-color: var(--white);
  width: 1rem;
  height: 1px;
}

.menu-drop-down {
  flex-flow: column;
  width: 100%;
  padding-top: 2.2rem;
  display: flex;
  position: relative;
  inset: 0% auto auto 0%;
}

.menu-scroll-hide {
  width: 100%;
  height: auto;
  position: absolute;
  overflow: hidden;
}

.comp-info {
  z-index: 100;
  grid-column-gap: 0rem;
  grid-row-gap: 0rem;
  background-color: var(--blue);
  flex-flow: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100svh;
  padding-left: 1rem;
  padding-right: 1rem;
  display: block;
  position: fixed;
  inset: 0% auto auto 0%;
}

.text-black {
  color: #242424;
}

.list-parent {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  color: var(--charcole);
  border-bottom: 1px solid #000;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1.2rem 2rem 1.2rem 1.2rem;
  font-size: 1.6rem;
  transition: color .4s, background-color .4s;
  display: flex;
  position: relative;
}

.list-parent:hover {
  background-color: #2424241a;
}

.list-parent.no-broder {
  border-bottom-style: none;
}

.number {
  font-size: 2.4rem;
}

.pull-down {
  margin-bottom: -4px;
  font-family: Oswald, sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
}

.info-col-parent-pop-up {
  flex-flow: column;
  justify-content: space-around;
  align-items: stretch;
  width: 40%;
  display: flex;
  overflow: hidden;
}

.comp-info-parent {
  border-right: 1px solid #000;
  flex-flow: column;
  justify-content: space-between;
  width: 60%;
  display: flex;
}

.border {
  background-color: var(--black);
  width: 100%;
  height: 1px;
}

.hero-title-contact {
  width: 52vw;
  font-size: 14vw;
}

.hero-title-contact.text-black {
  font-size: 12vw;
  line-height: 1;
}

.comp-scroll {
  width: 100%;
  height: 100%;
  display: flex;
}

.title-parent {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  flex-flow: column;
  display: flex;
}

.scroll-text {
  color: var(--charcole);
  letter-spacing: -1px;
  text-transform: uppercase;
  font-family: Ppfraktionmono, sans-serif;
  font-size: .8rem;
  display: none;
  position: absolute;
  inset: auto 1rem 1rem auto;
}

.outro-fade {
  z-index: 1;
  background-color: var(--black);
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  inset: 0% auto auto 0%;
}

.heading {
  font-family: Bulevar Poster, sans-serif;
  font-size: 6rem;
  line-height: 1.2;
}

.paragraph {
  color: var(--white);
  font-family: Oswald, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
}

.paragraph-2 {
  font-family: Oswald, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.heading-2 {
  font-family: Bulevar Poster, sans-serif;
  font-size: 6rem;
  line-height: 1.2;
}

.rolunkheroheading {
  font-family: Bulevar Poster, sans-serif;
  line-height: 1;
}

.div-block {
  background-color: var(--white-smoke);
  width: 100%;
  height: 2px;
  margin-top: 1.8rem;
}

.termekkep1 {
  z-index: 1;
  border-radius: 0;
  width: 100%;
  height: 90%;
  position: relative;
}

.termekkep1:hover {
  border-radius: 0;
}

.termekkep2 {
  z-index: 1;
  border-radius: 0;
  width: 100%;
  height: 90%;
  position: relative;
}

.paragraph-3 {
  z-index: 1;
  color: var(--white);
  font-family: Raleway Variablefont Wght, sans-serif;
  font-weight: 400;
  position: relative;
}

.bghover {
  background-color: var(--white-smoke);
  position: absolute;
  inset: 0%;
}

.parent-copy {
  grid-column-gap: 1.2rem;
  grid-row-gap: 1.2rem;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.namecontainer {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.paragraph-5, .paragraph-6 {
  z-index: 1;
  color: var(--white);
  font-family: Oswald, sans-serif;
  font-weight: 400;
  position: relative;
}

.rolunk-wrap {
  flex-flow: column;
  display: flex;
}

.section-1 {
  grid-column-gap: 10rem;
  grid-row-gap: 10rem;
  flex-flow: column;
  padding: 8rem 3rem;
  display: flex;
}

.content1 {
  grid-column-gap: 10rem;
  grid-row-gap: 10rem;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.heading-3 {
  width: 100%;
  font-size: 18vw;
  line-height: .7;
  display: flex;
  overflow: hidden;
}

.paragraph-8 {
  width: 60%;
  font-family: Oswald, sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

.content2 {
  grid-column-gap: 10rem;
  grid-row-gap: 10rem;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8rem 3rem;
  display: flex;
}

.div-block-3 {
  z-index: 1;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.heading-4 {
  z-index: 2;
  font-size: 4rem;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.image-2 {
  z-index: 1;
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
}

.heading-5 {
  font-size: 4rem;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.body-2 {
  overflow: visible;
}

.amarentiheadingwrap {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  display: flex;
  overflow: visible;
}

.section-2 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.heading-6 {
  font-size: 10.2vw;
}

.marquee-horizontal {
  z-index: 200;
  background-color: #0000;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.track-horizontal-alt {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.marquee-text-2 {
  color: #fff;
  text-transform: uppercase;
  flex: none;
  margin-right: 8vw;
  font-family: Oswald, sans-serif;
  font-size: 2rem;
}

.paragraph-9 {
  text-align: left;
  width: 50%;
  font-family: Oswald, sans-serif;
  font-weight: 400;
}

.section-4 {
  grid-column-gap: 5rem;
  grid-row-gap: 5rem;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 8rem 3rem 6rem;
  display: flex;
  overflow: visible;
}

.kartya-container {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.kartya-1 {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  background-color: var(--white-smoke);
  border-radius: 20px;
  flex-flow: column;
  width: 40%;
  height: auto;
  padding: 30px 15px 10px;
  display: flex;
  overflow: hidden;
}

.kartya-2 {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  background-color: var(--white-smoke);
  border-radius: 20px;
  flex-flow: column;
  width: 40%;
  height: auto;
  padding: 30px 15px 10px;
  display: flex;
}

.heading-7 {
  color: var(--charcole);
  font-size: 4rem;
  display: inline-block;
}

.kartyaheading-container {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.div-block-4 {
  background-color: var(--charcole);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.paragraph-10 {
  color: var(--charcole);
  font-family: Oswald, sans-serif;
  font-weight: 400;
}

.kep-container {
  border-radius: 20px;
  height: 100%;
  overflow: hidden;
}

.termekkep1rolunk {
  width: 100%;
  height: 90%;
}

.paragraph-11 {
  color: var(--charcole);
  font-family: Oswald, sans-serif;
  font-weight: 400;
}

.heading-8 {
  text-align: center;
  font-size: 10.2vw;
}

.marquee-horizontal-2 {
  z-index: 200;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.track-horizontal {
  justify-content: flex-start;
  align-items: center;
  font-family: Oswald, sans-serif;
  display: flex;
}

.marquee-text-3 {
  color: #fff;
  text-transform: uppercase;
  flex: none;
  margin-right: 8vw;
  font-size: 2rem;
}

.wearamarentiheadingcontainer {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  display: flex;
}

.vonal2, .spacer-vonal {
  background-color: var(--white-smoke);
  width: 100%;
  height: 2px;
}

.termekeinkheadingcontainer {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  display: flex;
}

.vonal {
  background-color: var(--white-smoke);
  height: 2px;
}

.csomag-link {
  color: var(--white-smoke);
  text-decoration: none;
}

.footer-heading-wrap {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  padding-left: 3rem;
  padding-right: 3rem;
  display: block;
}

.csomag-tartalma {
  color: #000;
  text-decoration: none;
}

.footer-head {
  margin-bottom: 20px;
  font-size: 10vw;
  line-height: 1.1;
  text-decoration: none;
  display: inline-block;
}

.instalink {
  text-decoration: none;
}

.kezd-lap-text {
  color: #000;
  font-size: 1.3vw;
}

.kezd-lap-text.footerszoveg, .kezd-lap-text.footerszoveg-copy {
  color: var(--white-smoke);
  font-family: Oswald, sans-serif;
  font-size: 1.1rem;
}

.div-block-25 {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
}

.socials-container {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.footervonal1 {
  background-color: #5f5f5f;
  width: 100%;
  height: 2px;
  margin-bottom: 40px;
}

.csomagtext-2 {
  font-size: 1.2rem;
}

.footer-jobb-2 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  color: var(--white-smoke);
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  font-family: Oswald, sans-serif;
  display: flex;
}

.csomagtartalma-text-2 {
  color: var(--white-smoke);
  font-size: 1.2rem;
}

.heading-11 {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Oswald, sans-serif;
  font-size: 1.3rem;
}

.heading-12, .felazoldaltetejerehead {
  margin-top: 0;
  font-family: Oswald, sans-serif;
  font-size: 1.3rem;
}

.footervonal2 {
  background-color: #5f5f5f;
  width: 100%;
  height: 2px;
  margin-bottom: 20px;
}

.div-block-28 {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.aszf-szallitas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.aszflink {
  text-decoration: none;
}

.aszftext {
  color: var(--white-smoke);
  font-family: Oswald, sans-serif;
  font-size: 1.1rem;
}

.szallitaslink {
  color: var(--white-smoke);
  text-decoration: none;
}

.szallitastext {
  font-family: Oswald, sans-serif;
  font-size: 1.1rem;
}

.text-block-3 {
  font-family: Oswald, sans-serif;
  font-size: 1.2rem;
}

.text-block-4 {
  font-size: 1.1rem;
}

.footer {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  padding-left: 3rem;
  padding-right: 3rem;
  display: block;
}

.instatext, .fbtext, .tttext {
  color: var(--white-smoke);
  font-family: Oswald, sans-serif;
  font-size: 1.1rem;
}

.tiktoklink, .fblink {
  text-decoration: none;
}

.felazoldaltetejerelink {
  color: var(--white-smoke);
  text-decoration: none;
}

.content-2telo {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8rem 3rem;
  display: none;
}

.text-black-copy {
  color: #242424;
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.link {
  color: var(--charcole);
}

.title-name-intro-2 {
  text-align: center;
  font-size: 14vw;
  line-height: .75;
}

.span-text-out, .span-text-in {
  display: inline-block;
  overflow: hidden;
}

.introhead-wrap {
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 22vw;
  }

  .info-col-child.pop-up {
    width: 100%;
    padding-left: 2rem;
  }

  .section.split-col {
    flex-flow: column;
    height: auto;
  }

  .rolunkherocontainer.flip {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }

  .col-content-parent {
    padding-top: 2rem;
  }

  .video-bg {
    min-width: auto;
  }

  .col-left {
    width: 100%;
    position: relative;
  }

  .parent.flex-split {
    justify-content: space-between;
    align-items: flex-start;
  }

  .col-right {
    width: 100%;
    height: 100vh;
    position: relative;
  }

  .grid-main.image {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    height: 20%;
  }

  .grid-main.text {
    grid-template-columns: 1fr 1fr 2fr;
  }

  .hero-parent {
    box-sizing: border-box;
    grid-column-gap: 10rem;
    grid-row-gap: 10rem;
    aspect-ratio: auto;
    object-fit: fill;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
  }

  .header-parent {
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
  }

  .serif {
    font-size: 2.4rem;
  }

  .text-parent {
    width: 100%;
  }

  .background-content {
    display: none;
  }

  .title-name-intro {
    font-size: 19vw;
  }

  .hero-title-contact {
    font-size: 17vw;
  }

  .parent-copy {
    flex-flow: column;
  }

  .content1 {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    flex-flow: column;
  }

  .paragraph-8 {
    width: 100%;
  }

  .content2 {
    flex-flow: column;
    display: none;
  }

  .heading-4, .heading-5 {
    font-size: 3rem;
  }

  .paragraph-9 {
    text-align: left;
    width: 100%;
  }

  .section-4 {
    justify-content: center;
    align-items: center;
  }

  .kezd-lap-text.footerszoveg, .kezd-lap-text.footerszoveg-copy, .csomagtext-2, .csomagtartalma-text-2 {
    font-size: 1rem;
  }

  .footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .content-2telo {
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .section-3telo {
    flex-flow: column;
    display: flex;
  }

  .title-name-intro-2 {
    font-size: 19vw;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 25vw;
  }

  .info-col-child.pop-up {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    min-width: 31rem;
    padding: 2rem;
  }

  .col-content-parent {
    padding-right: 2rem;
  }

  .tag-sm.nav {
    background-color: var(--black);
    width: 100%;
  }

  .primary-btn.menu {
    background-color: var(--black);
  }

  .grid-main.image {
    flex-flow: column;
    height: auto;
  }

  .nav-flex.hide-mobile {
    display: none;
  }

  .hero-parent {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    height: auto;
  }

  .header-parent {
    height: 40rem;
    padding-top: 10rem;
  }

  .title-name-intro {
    font-size: 24vw;
  }

  .menu-parent {
    background-color: #000;
    left: -1px;
  }

  .menu-parent:hover {
    background-color: #000;
  }

  .menu-drop-down {
    padding-top: 2.2rem;
  }

  .comp-info {
    flex-flow: column;
  }

  .list-parent.no-broder {
    border-bottom-style: solid;
  }

  .info-col-parent-pop-up {
    flex-flow: row;
    width: 100%;
    overflow: auto;
  }

  .comp-info-parent {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    width: 100%;
  }

  .hero-title-contact {
    font-size: 18vw;
  }

  .hero-title-contact.text-black {
    width: 100%;
  }

  .comp-scroll {
    flex-flow: column;
  }

  .scroll-text {
    display: block;
  }

  .section-1 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .div-block-3 {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }

  .heading-4, .heading-5 {
    font-size: 2.5rem;
  }

  .section-4 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .kartya-container {
    flex-flow: column;
  }

  .kartya-1, .kartya-2 {
    width: 100%;
  }

  .footer-heading-wrap {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0%;
  }

  .footer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .content-2telo {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .title-name-intro-2 {
    font-size: 24vw;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 27vw;
  }

  h2 {
    font-size: 4.4rem;
  }

  p {
    font-size: 1rem;
  }

  a {
    color: var(--charcole);
  }

  .rolunkhero.bg-blue {
    border-bottom: 1px solid #7f7a7a40;
  }

  .rolunkhero.bg-pink {
    border-top: 1px solid #7f7a7a40;
  }

  .info-col-child {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    width: 100%;
  }

  .info-col-child.pop-up {
    min-width: 100%;
    padding-top: 1rem;
    padding-left: 1.2rem;
    padding-right: 1.4rem;
    position: relative;
  }

  .rolunkherocontainer.flip {
    grid-column-gap: 1.8rem;
    grid-row-gap: 1.8rem;
  }

  .col-content-parent {
    padding-bottom: 3rem;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }

  .rolunkheroheadingdiv.full-right {
    justify-content: flex-start;
    align-items: center;
  }

  .col-left {
    position: sticky;
    top: 0;
  }

  .col-left:hover {
    padding: 0;
  }

  .parent.flex-split {
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .col-right:hover {
    padding: 0;
  }

  .nav-bar {
    padding-left: 1rem;
    padding-right: 1rem;
    display: block;
  }

  .logo {
    height: 2.6rem;
    margin-top: .8rem;
  }

  .grid-main.image {
    flex-flow: column;
    height: auto;
  }

  .hero-parent {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .header-parent {
    height: auto;
  }

  .title-name-intro {
    font-size: 26vw;
  }

  .flex-menu {
    width: 50%;
  }

  .comp-info {
    height: 100vh;
    display: block;
  }

  .list-parent {
    justify-content: flex-start;
    align-items: flex-start;
    padding: .6rem .8rem .6rem 0;
  }

  .number {
    font-size: 1.8rem;
  }

  .pull-down {
    font-family: Oswald, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }

  .comp-info-parent {
    grid-column-gap: 1.2rem;
    grid-row-gap: 1.2rem;
  }

  .hero-title-contact {
    font-size: 17vw;
  }

  .hero-title-contact.text-black {
    font-size: 4rem;
  }

  .title-parent {
    grid-column-gap: 1.4rem;
    grid-row-gap: 1.4rem;
  }

  .scroll-text {
    font-family: PT Sans, sans-serif;
  }

  .termekkep1, .termekkep2 {
    z-index: 1;
  }

  .parent-copy {
    flex-flow: column;
  }

  .paragraph-5, .paragraph-6 {
    z-index: 1;
  }

  .section-1 {
    padding: 4rem 1rem 2rem;
  }

  .content1 {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    text-align: left;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .heading-3 {
    font-size: 4rem;
  }

  .paragraph-8 {
    text-align: left;
    width: 100%;
  }

  .content2 {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 1rem;
    display: none;
  }

  .div-block-3 {
    flex-flow: column;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .heading-6 {
    font-size: 4rem;
  }

  .marquee-text-2 {
    font-size: 1.5rem;
  }

  .paragraph-9 {
    text-align: left;
    width: 100%;
  }

  .section-4 {
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 1rem;
  }

  .kartya-container {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    flex-flow: column;
  }

  .kartya-1 {
    grid-column-gap: .6rem;
    grid-row-gap: .6rem;
    width: 100%;
  }

  .kartya-2 {
    grid-column-gap: .6rem;
    grid-row-gap: .6rem;
    width: 100%;
    margin-top: 0;
  }

  .heading-7 {
    font-size: 3rem;
  }

  .heading-8 {
    text-align: center;
    font-size: 4rem;
  }

  .marquee-text-3 {
    font-size: 1.5rem;
  }

  .footer-heading-wrap {
    flex-flow: column;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .footer-head {
    font-size: 4rem;
  }

  .div-block-25 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-flow: column;
  }

  .footer-jobb-2 {
    width: 100%;
  }

  .div-block-28 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .aszf-szallitas {
    flex-flow: column;
  }

  .szallitastext, .text-block-4 {
    font-size: 1rem;
  }

  .footer {
    flex-flow: column;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .instatext, .fbtext, .tttext {
    font-size: 1rem;
  }

  .content-2telo {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 1rem;
    display: flex;
  }

  .text-black-copy {
    font-family: Oswald, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .italic-text {
    font-style: normal;
  }

  .title-name-intro-2 {
    font-size: 26vw;
  }
}

#w-node-_7bc8e676-29f4-2778-c870-27f70802723c-d5c1a814 {
  place-self: center start;
}

#w-node-_222484a3-18fe-6908-b0c0-9cb009a0648f-d5c1a814 {
  place-self: end;
}

#w-node-_9ac6261d-ccca-594a-99a8-356049cf0570-d5c1a815, #w-node-_9ac6261d-ccca-594a-99a8-356049cf0572-d5c1a815 {
  place-self: center start;
}

#w-node-_9ac6261d-ccca-594a-99a8-356049cf0574-d5c1a815 {
  place-self: end;
}

#w-node-_787c1cce-d092-f27f-048e-6af969c23da9-d5c1a816, #w-node-_787c1cce-d092-f27f-048e-6af969c23dab-d5c1a816 {
  place-self: center start;
}

#w-node-_787c1cce-d092-f27f-048e-6af969c23dad-d5c1a816 {
  place-self: end;
}

#w-node-_597ac81c-5054-b146-48cc-3d95806c41eb-d5c1a816 {
  grid-area: span 1 / span 2 / span 1 / span 2;
  align-self: end;
}

@media screen and (max-width: 767px) {
  #w-node-_7bc8e676-29f4-2778-c870-27f70802723c-d5c1a814 {
    grid-area: span 1 / span 3 / span 1 / span 3;
    place-self: center;
  }

  #w-node-_222484a3-18fe-6908-b0c0-9cb009a0648f-d5c1a814 {
    grid-area: span 1 / span 3 / span 1 / span 3;
    place-self: end stretch;
  }

  #w-node-_9ac6261d-ccca-594a-99a8-356049cf0570-d5c1a815 {
    grid-area: span 1 / span 3 / span 1 / span 3;
    place-self: center;
  }

  #w-node-_9ac6261d-ccca-594a-99a8-356049cf0574-d5c1a815 {
    grid-area: span 1 / span 3 / span 1 / span 3;
    place-self: end stretch;
  }

  #w-node-_787c1cce-d092-f27f-048e-6af969c23da9-d5c1a816 {
    grid-area: span 1 / span 3 / span 1 / span 3;
    place-self: center;
  }

  #w-node-_787c1cce-d092-f27f-048e-6af969c23dad-d5c1a816 {
    grid-area: span 1 / span 3 / span 1 / span 3;
    place-self: end stretch;
  }

  #w-node-_597ac81c-5054-b146-48cc-3d95806c41eb-d5c1a816 {
    grid-area: span 1 / span 3 / span 1 / span 3;
    align-self: start;
  }

  #w-node-_79ecd13d-6864-2c8e-fb8d-68c1d705f0b5-d5c1a816, #w-node-f25cacf6-2867-1b9f-0903-a15f32fca708-d5c1a816 {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_7bc8e676-29f4-2778-c870-27f70802723c-d5c1a814, #w-node-_9ac6261d-ccca-594a-99a8-356049cf0570-d5c1a815, #w-node-_787c1cce-d092-f27f-048e-6af969c23da9-d5c1a816 {
    justify-self: center;
  }
}


@font-face {
  font-family: 'Ppeditorialnew';
  src: url('../fonts/PPEditorialNew-UltralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway Variablefont Wght';
  src: url('../fonts/Raleway-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bulevar Poster';
  src: url('../fonts/Bulevar-Poster.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Ppfraktionmono';
  src: url('../fonts/PPFraktionMono-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Identidad';
  src: url('../fonts/Identidad-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
.primary-btn.menu,
.primary-btn.ghost .button-text-ab.text-black {
  color: #000000;
  transition: color 0.6s ease;
  
}

.primary-btn.menu:hover,
.primary-btn.ghost:hover .button-text-ab.text-black {
  color: #ffffff;
}