html {
  font-size: calc(0.625rem + 0.41666666666666663vw);
}
@media screen and (max-width: 1920px) {
  html {
    font-size: calc(0.625rem + 0.41666666666666674vw);
  }
}
@media screen and (max-width: 1440px) {
  html {
    font-size: calc(0.8126951092611863rem + 0.20811654526534862vw);
  }
}
@media screen and (max-width: 479px) {
  html {
    font-size: calc(0.7494769874476988rem + 0.8368200836820083vw);
  }
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.track-horizontal-alt {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee-horizontal-alt 40s linear infinite;
  /* manipulate the speed of the marquee by changing "40s" line above*/
}
@keyframes marquee-horizontal-alt {
  from { transform: translateX(-50%); }
  to { transform: translateX(0%); }
}
.track-horizontal {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee-horizontal 40s linear infinite;
  /* manipulate the speed of the marquee by changing "40s" line above*/
}
@keyframes marquee-horizontal {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}
.menu-bar {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.menu-parent:hover .menu-bar.top,
.menu-parent:hover .menu-bar.bottom {
  background-color: white;
}

.menu-scroll-hide {
  display: none;
  opacity: 0;
  transform: translate3d(0, 10px, 0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.menu-scroll-hide.visible {
  display: block;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.primary-btn.menu {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.primary-btn.menu.one.visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}

.primary-btn.menu.two.visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.1s;
}

.primary-btn.menu.three.visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.2s;
}

.primary-btn.menu.four.visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.3s;
}
@keyframes colorChange {
  0% { background-color: #FF5733; }
  6.67% { background-color: #33FF57; }
  13.34% { background-color: #3357FF; }
  20.01% { background-color: #F3FF33; }
  26.68% { background-color: #FF33A6; }
  33.35% { background-color: #33FFF3; }
  40.02% { background-color: #F333FF; }
  46.69% { background-color: #33F3FF; }
  53.36% { background-color: #FF5733; }
  60.03% { background-color: #33FF57; }
  66.7% { background-color: #3357FF; }
  73.37% { background-color: #F3FF33; }
  80.04% { background-color: #FF33A6; }
  86.71% { background-color: #33FFF3; }
  93.38% { background-color: #F333FF; }
  100% { background-color: #33F3FF; }
}

.kezd-lap-text, .footerszoveg, .csomagtext-2, .csomagtartalma-text-2, .instatext, .fbtext, .tttext {
  color: white;
  transition: color 0.3s ease;
}

.grey-text {
  color: grey !important;
}

@keyframes scaleDown {
  from {
    transform: scale3d(1.2, 1.2, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes slideUp {
  from {
    transform: translate3d(0, 110%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 10%, 0);
    opacity: 1;
  }
}

@keyframes lineAppear {
  from {
    transform: translate3d(-105%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.span-text {
  display: inline-block;
}

.scale-down {
  animation: scaleDown 2s ease forwards;
}

.slide-up {
  animation: slideUp 0.5s ease forwards;
}

.line-appear {
  animation: lineAppear 1s ease forwards;
}
.div-block-3 h2 .span-text {
  margin: 0px 5px 0px 5px;
}
.kartya-1, .kartya-2 {
  transition: transform 0.5s ease-in-out;
}

.kep-container img {
  transition: transform 0.5s ease-in-out;
}

.kep-container img:hover {
  transform: scale(1.2);
  
}
.button-text {
  transition: color ease 0.5s;
}
.primary-btn:hover .button-text {
  color:black;
  z-index: 1000;
}
